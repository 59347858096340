@import url(https://rsms.me/inter/inter.css);
html,
body {
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
}

@supports (font-variation-settings: normal) {
  html {
    font-family: "Inter var", sans-serif;
  }
}

